<template>
  <div class="h-100">
    <h4 class="text-center py-4 m-0 text-gray-900">Print Barcode</h4>
    <div class="wrapper">
      <div class="m-0 g-5 barcode-wrapper" id="printContent" v-if="tempData.type == 'item'">
        <div id="printContent" class="barcode-list" v-for="(item, i) in data" :key="i">
          <div class="barcode-content bg-white">
            <div class="row mx-0 mb-1 lh-sm">
              <div class="row m-0 g-0 col fw-bolder">
                <span class="align-self-center label-font" style="font-size:7.5px">{{ storeName.toUpperCase() }}</span>
                <span class="align-self-center label-font">{{ item.name }}</span>
              </div>
              <div class="d-flex py-1 px-0 col-auto">
                <span class="align-self-center fw-bolder fs-9 bg-black text-white data-highlight">{{ item.size }}</span>
              </div>
            </div>
            <div class="mb-1">
              <vue-barcode
                style="min-height: 24px;"
                class="w-100"
                tag="img"
                :value="item.sku"
                :options="{
                      format: 'CODE128',
                      displayValue: false,
                      fontSize: 14,
                      width: 1,
                      height: 24,
                      margin: 0,
                  }"
              />
            </div>
            <div class="d-flex fw-boldest">
              <div class="row mx-0 g-0">
                <div class="align-self-center label-font lh-sm" style="font-size:10px">{{ item.artikel }}</div>
                <div class="align-self-center label-font lh-sm" style="font-size:9px">{{ item.color }}</div>
              </div>
              <div class="row text-end m-0 justify-content-end">
                <div class="align-self-center p-0 label-font lh-md">{{ item.sku }}</div>
                <div class="d-flex w-auto bg-black align-self-center text-white data-highlight px-3 lh-sm">
                  <span class="fs-8">{{ formatIDR(finalPrice, { prefix: 'IDR ' }) }}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-0 g-5 barcode-wrapper" id="printContent" v-else-if="tempData.type == 'desain'">
        <div id="printContent" class="barcode-list border" v-for="(item, i) in data" :key="i">
          <div class="barcode-content bg-white">
            <div class="d-flex align-items-center h-100 justify-content-between">
              <div class="pa-2">
                <div class="row mx-0 lh-sm mb-1">
                  <div class="row m-0 g-0 col fw-bolder">
                    <span class="fs-2">{{ item.folder_name }}</span>
                  </div>
                </div>
                <div class="row mx-0 lh-sm">
                  <div class="row m-0 g-0 col">
                    <span class="fw-bolder fs-1">{{ item.desain_id }}</span>
                  </div>
                </div>
              </div>
              <div class="pa-2 h-100">
                <img :src="img_url_desain+item.image" style="width: 100%; height: 100%; object-fit: cover;"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-0 g-5 barcode-wrapper" id="printContent" v-else-if="tempData.type == 'display'">
        <div id="printContent" class="barcode-list border" v-for="(item, i) in data" :key="i">
          <div class="barcode-content bg-white">
            <div class="d-flex align-items-center h-100 justify-content-between">
              <div class="pa-1" style="width: 55%;">
                <div class="lh-sm mb-1">
                  <div class="fw-bolder">
                    <span class="fs-6">{{ item.raw_category }}</span>
                  </div>
                  <div class="fw-bolder">
                    <span class="fs-6">{{ item.color }}</span>
                  </div>
                </div>
                <div class="lh-sm mb-1">
                  <div class="fw-bolder">
                    <span class="fs-4">{{ item.folder }}</span>
                  </div>
                  <div class="fw-bolder">
                    <span class="fs-4">{{ item.desain_id }}</span>
                  </div>
                </div>
              </div>
              <div class="pa-2 h-100" style="width: 45%;">
                <img :src="img_url_desain+item.image" style="width: 100%; height: 100%; object-fit: cover;"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-0 g-5 barcode-wrapper" id="printContent" v-else>
        <h2 class="py-7">Sorry, something error.</h2>
      </div>
    </div>
    <div class="row my-4 mx-0 justify-content-center">
      <div class="col-auto">
        <button ref="print" v-print="'#printContent'" class="btn btn-primary">Print</button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
body {
  background-image: #fffff !important;
}

.wrapper {
  background: #ddd;
  max-height: 80vh;
  overflow: hidden;
  margin: 0 auto;
  width: calc(188.976377953px * 2 + 8px);
}

$barcode_height: 24mm; //88px;
$barcode_width: 188.976377953px;
$barcode_margin: 0px; //4px;
$barcode_padding: 6px 6px 6px 12px;

.barcode-wrapper {
  -webkit-print-color-adjust: exact !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 50%));
  grid-auto-rows: minmax(min-content, max-content);

  .barcode-list {
    margin: $barcode_margin;

    .barcode-content {
      // 50mm x 25mm
      height: calc($barcode_height - ($barcode_margin * 2));
      display: flex;
      flex-direction: column;
      padding: $barcode_padding;
      // font-family: 'PT Serif', serif !important;

      .label-font {
        font-size: 8px;
      }

      .barcode {
        padding: 2px 0;
        > img {
          width: 100%;
          height: 100%;
        }
      }

      span {
        font-size: 8.5px;
        font-weight: 700 !important;
      }

      .data-highlight {
        border-radius: 2px;
        padding: 1px 4px;
        min-width: 25px !important;
        display: inline-block;
        text-align: center;
      }
    }
  }
}

canvas {
  width: 100% !important;
  height: 100% !important;
}

</style>

<script>
import { defineComponent, onMounted, ref, reactive, watchEffect, watch, computed, onUpdated, inject } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatIDR } from "@/helpers/functions";
//import TableLite from 'vue3-table-lite/src/components/TableLite.vue'
import Datatable from "@/components/kt-datatable/KTDatatable.vue"
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { ErrorMessage, Field, Form } from "vee-validate";
import InnerImageZoom from 'vue-inner-image-zoom';
import { InfoFilled } from '@element-plus/icons-vue'
import print from 'vue3-print-nb'
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  directives: {
    print
  },
  components: {
    //Datatable,
    //Field,
    //Form,
    //ErrorMessage,
    //'inner-image-zoom': InnerImageZoom,
  },
  setup() {
    const store = useStore();
    const router = useRouter()

    const img_url_products = ref(process.env.VUE_APP_IMAGE_PRODUCTS)
    const img_url_desain = ref(process.env.VUE_APP_IMAGE_DESAINS)
    const print = ref(null)

    const data = reactive([])
    const finalPrice = ref(0)
    const storeName = ref('Nimco')

    const tempData =  reactive({
      type: null,
    })

    const getData = async() => {
      try {
        await data.splice(0) // reset value

        if(localStorage.getItem("raw_barcode")) {
          let setData = await JSON.parse(localStorage.getItem("raw_barcode"));

          //console.log(setData)

          if(setData.length == 0) {
            throw new Error('No data found');
          }

          await Object.assign(data, setData.data)

          if(setData.type == 'item') {
            storeName.value = setData.store
            finalPrice.value = await setData.price
            tempData.type = setData.type
          } else {
            tempData.type = setData.type
          }

        } else {
          throw new Error('No data found')
        }

      } catch(e) {
        console.error()
      }

    }

    onMounted(async() => {
      setCurrentPageBreadcrumbs("Barcode Generator", ["Barcode Generator"]);

      await getData()

      setTimeout(() => {
        //console.log(print.value)
        //print.value.$el.click()

      }, 3000)
    })

    return {
      router,
      data, finalPrice, storeName, tempData, print,
      formatIDR,
      img_url_desain,
    }
  }
})
</script>